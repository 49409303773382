/* CSS Document */
.pngfix{
	behavior: url(/include/iepngfix.htc);
}

.cropped_img_overlay{
	font-size:0;
	background-color:#CC0000; 
	opacity:0.5; 
	filter:alpha(opacity=50); 
	position:absolute; 
	width:100%;	
	height:100%;
	z-index:10;
}

.button {
    min-width: 0;
}
